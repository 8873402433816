import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// pages here
import Index from "./pages/index";
import { ResetConfirm, ResetRequest } from "./pages/auth/reset";
import { RegisterForm, RegisterVerify } from "./pages/auth/register";
import NoteBook from "./pages/notebook";
import Timeline from "./pages/tests/timeline";
import HomePageV3 from "./pages/v3/HomePage";

const FormulaSheets = lazy(() => import("./pages/formula-sheets"));
const RevisionNotes = lazy(() => import("./pages/revision-notes"));

const Onboarding = lazy(() => import("./pages/onboarding"));
const ErrorPage = lazy(() => import("./pages/error"));
const DashboardPage = lazy(() => import("./pages/dashboard/index"));
const Test = lazy(() => import("./pages/packs/test/test"));
const LeaderBoard = lazy(() => import("./pages/packs/test/leaderboard"));
const Version = lazy(() => import("./pages/version"));

const Profile = lazy(() => import("./pages/account/profile"));
const Settings = lazy(() => import("./pages/account/settings"));
const PreparationDetails = lazy(() =>
  import("./pages/account/preparation-details")
);
const Solutions = lazy(() => import("./pages/packs/test/solutions"));
const TestSeries = lazy(() => import("./pages/test-series"));

// Pack Analysis
const PackAnalysisHome = lazy(() =>
  import("./pages/packs/analysis/PackAnalysisHome")
);

// Session Recordings
const SessionRecordings = lazy(() => import("./pages/v2/session-recordings"));

// RESO
const Reso = lazy(() => import("./pages/reso"));
const ResoChapter = lazy(() => import("./pages/reso/Subject/Chapter"));

// Auth Routes
const Auth = lazy(() => import("./pages/auth"));
const AuthAdmin = lazy(() => import("./pages/auth/admin"));
const Logout = lazy(() => import("./pages/auth/logout"));

// Test Routes
const TestUI = lazy(() => import("./pages/tests"));
const Inst = lazy(() => import("./pages/tests/instructions"));
const Sub = lazy(() => import("./pages/tests/submission"));
const Login = lazy(() => import("./pages/auth/login"));
const Maintenance = lazy(() => import("./pages/maintenance"));
const GraphTest = lazy(() => import("./pages/graphs"));

// V2 Design Routes
const Home = lazy(() => import("./pages/v3/HomePage"));
const Dash = lazy(() => import("./pages/v2/dash"));
const OverallAnalysis = lazy(() => import("./pages/v2/overallPackAnalysis"));
const SubjectAnalysis = lazy(() => import("./pages/v2/subjectWiseAnalysis"));
const ChapterAnalysis = lazy(() => import("./pages/v2/chapterWiseAnalysis"));

const PackHomeV3 = lazy(() => import("./pages/packs/PackHomeV3"));
const PackProgress = lazy(() => import("./pages/packs/progress/PackProgress"));

const Coupons = lazy(() => import("./pages/coupons"));

const UP = JSON.parse(localStorage.getItem("userProfile"));
const bg = UP ? (UP.settings.isDarkTheme ? "#12263F" : "white") : "white";

const Router = () => {
  return process.env.REACT_APP_MAINTENANCE_MODE ? (
    <Maintenance />
  ) : (
    <BrowserRouter>
      <Suspense
        fallback={<div style={{ height: "100vh", backgroundColor: bg }}></div>}
      >
        <Switch>
          <Route path="/" component={Index} exact />
          <Route path="/auth" component={Auth} exact />
          <Route path="/auth/admin" component={AuthAdmin} exact />
          <Route path="/auth/register" component={RegisterForm} exact />
          <Route path="/auth/verifyEmail" component={RegisterVerify} exact />
          <Route path="/auth/login" component={Login} exact />
          <Route path="/auth/reset/request" component={ResetRequest} exact />
          <Route path="/auth/reset/confirm" component={ResetConfirm} exact />
          <Route path="/account" component={Profile} exact />
          <Route path="/logout" component={Logout} exact />
          <Route path="/onboarding" component={Onboarding} exact />
          <Route path="/dashboard" component={DashboardPage} exact />
          <Route path="/test-series" component={TestSeries} exact />

          <Route path="/account/profile" component={Profile} />
          <Route path="/account/settings" component={Settings} />
          <Route
            path="/account/preparation-details"
            component={PreparationDetails}
          />
          <Route
            path="/account/preparation-details"
            component={PreparationDetails}
          />

          <Route path="/packs/:packId/progress" component={PackProgress} />
          <Route path="/packs/:packId" component={PackHomeV3} />

          <Route path="/packs/:packId" component={PackHomeV3} />

          {/* V2 Design Routes */}
          <Route path="/v2/home" component={Home} />
          <Route path="/v2/dash" component={Dash} />
          <Route path="/v2/overall-pack-analysis" component={OverallAnalysis} />
          <Route path="/v2/subject-wise-analysis" component={SubjectAnalysis} />
          <Route path="/v2/chapter-wise-analysis" component={ChapterAnalysis} />

          {/* V3 Design Routes */}
          <Route path="/v3/home" component={HomePageV3} />

          {/* Pack Analysis */}
          <Route path="/v2/:packId/analysis" component={PackAnalysisHome} />

          {/* Revision and Formula notes */}
          <Route
            path={`/v2/:packId/revision-notes`}
            component={RevisionNotes}
          />
          <Route
            path={`/v2/:packId/formula-sheets`}
            component={FormulaSheets}
          />
          <Route
            path={`/v2/:packId/session-recordings`}
            component={SessionRecordings}
          />

          {/* Notebook Routes */}
          <Route path={`/v2/notebooks`} component={NoteBook} />
          <Route
            path={`/v2/reso/:subjectId/:chapterId`}
            component={ResoChapter}
          />
          <Route path={`/v2/reso`} component={Reso} />

          {/* Test Routes */}
          <Route path="/tests/timeline" component={Timeline} exact />
          <Route path="/tests/:pack/:test/submission" component={Sub} exact />
          <Route
            path="/tests/:pack/:test/instructions"
            component={Inst}
            exact
          />
          <Route path="/tests/:pack/:test" component={TestUI} exact />

          <Route path="/:packId/:testId/solutions" component={Solutions} />
          <Route path="/:packId/:testId/leaderboard" component={LeaderBoard} />
          <Route path="/:packId/:testId" component={Test} />
          <Route path="/version" component={Version} exact />
          <Route path="/graphs" component={GraphTest} exact />
          <Route path="/coupons" component={Coupons} />

          {/* Error Routes */}
          <Route path="/no-connection">
            <ErrorPage type={504} />
          </Route>
          <Route path="*">
            <ErrorPage type={404} />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
